import { GET_ACTIVITY_TODAY_EMPLOYEE, ACTIVITY_REPORT } from '../../utils/endpoints';

/**
 * Fetch today's activities for a given employee number.
 * @param {string} employeeNumber - The employee number to query.
 * @returns {Promise<object>} - The response data from the server.
 * @throws {Error} - Throws an error if the fetch fails or the server returns an error.
 * @author JDGT
 */
export async function fetchTodayActivities(employeeNumber) {
  if (!employeeNumber) {
    throw new Error("Employee number is required.");
  }

  const endpoint = `${GET_ACTIVITY_TODAY_EMPLOYEE}${encodeURIComponent(employeeNumber)}`;
  console.log("endpoint: ", endpoint);

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      return [];
      /* const errorData = await response.json();
      throw new Error(
        errorData.message || `Failed to fetch activities. Status: ${response.status}`
      ); */
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching today's activities:", error);
    throw new Error(`Error fetching today's activities: ${error.message}`);
  }
}

/**
 * Function to consume the create activity endpoint
 * @param {Object} data - The payload to be sent in the POST request
 * @param {string} url - The endpoint URL
 * @returns {Promise<Object>} - The response data from the server
 * @throws {Error} - If the request fails or the server returns an error
 */
export async function createActivity(data) {
  console.log("Payload enviado:", data);
  try {
    const response = await fetch(`${ACTIVITY_REPORT}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorDetails = await response.json(); // Leer el cuerpo del error
      console.error('Detalles del error del servidor:', errorDetails);
      throw new Error(`HTTP error! status: ${response.status} - ${JSON.stringify(errorDetails)}`);
    }

    //return await response.json(); // Retornar la respuesta como JSON
    return response;
  } catch (error) {
    console.error('Error creando actividad:', error);
    throw error;
  }
}

/**
 * Function to consume the addTodayActivity endpoint.
 * Author: JDGT
 * @param {string} reason - The reason for the activity.
 * @param {string} employeeNumber - The employee number.
 * @param {Oject} data - Activity data.
 * @returns {Promise<Object>} - The response data from the server.
 * @throws {Error} - Throws an error if the request fails or the server returns an error.
 */
export async function addTodayActivity(reason, employeeNumber, data) {
  const endpointUrl = `${ACTIVITY_REPORT}/add`; // Replace with the actual URL of the endpoint

  const payload = {
    reason,
    employee_number: employeeNumber,
    data,
  };

  try {
    const response = await fetch(endpointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to add today activity');
    }

    const responseData = await response.json();
    //return responseData;
    return response;
  } catch (error) {
    console.error('Error adding today activity:', error.message);
    throw error;
  }
}

/**
 * Fetch the activity summary from the server and handle the response.
 * @param {string} employeeNumber - The employee number.
 * @param {string} typeOfJob - The type of job.
 * @returns {Promise<void>} - A promise that resolves when the image is processed.
 */
export async function fetchActivitySummary(employeeNumber, typeOfJob) {
  const queryParams = new URLSearchParams({ employee_number: employeeNumber, typeOfJob });

  try {
    const response = await fetch(`${ACTIVITY_REPORT}/summary?${queryParams.toString()}`, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    // Convierte la respuesta a un blob (contenido binario)
    const blob = await response.blob();

    // Verifica el blob
    console.log('Blob size:', blob.size);
    console.log('Blob type:', blob.type);

    if (blob.size === 0 || blob.type !== 'image/jpeg') {
      throw new Error('The image data is invalid.');
    }

    // Descarga la imagen
    const downloadLink = document.createElement('a');
    const imageUrl = URL.createObjectURL(blob);
    downloadLink.href = imageUrl;
    downloadLink.download = `activity_summary_${employeeNumber}.jpg`; // Nombre del archivo
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    console.log('Image downloaded successfully.');
  } catch (error) {
    console.error('Failed to fetch and download activity summary:', error);
  }
}
