export function get_permissions() {
    const token = localStorage.getItem('token');

    if (token) {
        try {
            const tokenParts = token.split('.');
            const payload = JSON.parse(atob(tokenParts[1]));
            console.log("payload: ", payload);
            const permissions = payload.permissions;
            const walk_ins = payload.walk_ins;
            const training_logs = payload.training_logs;
            const employee_number = payload.employee_number;
            const name = payload.name;
            const last_name = payload.last_name;
            const department = payload.department;
            const accounting = payload.accounting;
            const accounting_validation = payload.accounting_validation;
            const marketing = payload.marketing;
            const fleet = payload.fleet;
            const hr = payload.hr;
            const it = payload.it;
            const hr_philippines = payload.hr_philippines;
            const activity_report = payload.activity_report;
            return {
                permissions: permissions,
                walk_ins: walk_ins,
                training_logs: training_logs,
                employee_number: employee_number,
                name: name,
                last_name: last_name,
                department: department,
                accounting: accounting,
                accounting_validation: accounting_validation,
                marketing: marketing,
                fleet: fleet,
                hr: hr,
                it: it,
                hr_philippines: hr_philippines,
                activity_report: activity_report,
            };
        } catch (error) {
            return { permissions: null };
        }
    } else {
        return { permissions: null };
    }
}
