<template>
    <admin-activity-report />
</template>

<script>
import AdminActivityReport from '../../components/Sales/AdminActivityReport.vue'

export default {
    name: 'AdminActivityReportConsole',

    components: {
        AdminActivityReport,
    },
}
</script>