import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePageView from '../views/HomePageView.vue'
import WalkIns from '../views/Walk _ins/WalkInsView.vue'
import UserWalkInsConsole from '../views/Walk _ins/UserWalkInsConsoleView.vue'
import LoginView from '../views/Login/LoginView.vue';
import Employees from '../views/Employees/EmployeesView.vue';
import Policies from '../views/Employees/PoliciesView.vue';
import EmployeesFormView from '../views/Employees/EmployeesFormView.vue';
import TrainingLogView from '../views/Training_log/TrainingLogView.vue';
import NewTrainingLogView from '../views/Training_log/NewTrainingLogView.vue';
import PermissionsConsoleView from '../views/Permissions/PermissionsConsoleView.vue';
import TrainerInterface from '../views/Training_log/TrainerInterfaceView.vue';
import NewTrainerInterface from '../views/Training_log/NewTrainerInterfaceView.vue';
import AdminTrainerInterface from '../views/Training_log/AdminTrainerInterfaceView.vue';
import NewAdminTrainerInterface from '../views/Training_log/NewAdminTrainerInterfaceView.vue';
import { isAuthenticated } from '../utils/auth.js';
import AdminWalkInsConsole from '../views/Walk _ins/AdminWalkInsConsoleView.vue'
import Locations from '../views/Employees/LocationsView.vue';
import Vehicles from '../views/Fleet/VehiclesView.vue';
import Instructors from '../views/Fleet/InstructorsView.vue';
import ZipCodes from '../views/Accounting/ZipCodesView.vue';
import ChartOfAccount from '../views/Accounting/ChartOfAccountView.vue';
import ExpenseType from '../views/Accounting/ExpenseTypeView.vue';
import Banks from '../views/Accounting/BanksView.vue';
import CreditCardManagementView from '../views/Accounting/CreditCardManagementView.vue';
import CreditCardCarView from '../views/Accounting/CreditCardCarView.vue';
import PaidThroughAccountView from '../views/Accounting/PaidThroughAccountView.vue';
import InsuranceView from '../views/Fleet/InsuranceView.vue';
import PayrollView from '../views/Payroll/PayrollView.vue';
import EmailValidatorView from '../views/Marketing/EmailValidatorView.vue';
import TypeOfCoverageView from '../views/Fleet/Insurance/TypeOfCoverageView.vue'
import TypeOfComissionsView from '../views/Employees/TypeOfComissionsView'
import NovaAnalyticsView from '../views/Marketing/NovaAnalyticsView.vue';
import ExpenseRecordView from '../views/Accounting/ExpenseRecordView.vue';
import LeadSystemView from '../views/Marketing/LeadSystemView.vue';
import ReportToView from '../views/Employees/ReportToView'
import ExpenseRecordAdminPanelView from '../views/Accounting/ExpenseRecordAdminPanelView.vue';
import ComputerEquipmentView from '../views/It/ComputerEquipmentView.vue';
import ComputerEquipmentAssignationView from '../views/It/ComputerEquipmentAssignationView.vue';
import ZohoChartOfAccountView from '../views/Accounting/Zoho/ZohoChartOfAccountView.vue';
import ZohoVendorsView from '../views/Accounting/Zoho/ZohoVendorsView.vue';
import ZohoCurrencyView from '../views/Accounting/Zoho/ZohoCurrencyView.vue';
import ZohoBanksView from '../views/Accounting/Zoho/ZohoBanksView.vue';
import PorcetagesInterfaceView from '../views/Accounting/PorcetagesInterfaceView.vue';
import LocationExcelView from '../views/Accounting/LocationExcelView.vue';
import UserActivityReportView from '../views/Sales/UserActivityReportView.vue'
import AdminActivityReportView from '../views/Sales/AdminActivityReportView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/permissions',
    name: 'permissions',
    component: PermissionsConsoleView,
    meta: { requiresAuth: true }
  },
  {
    path: '/home',
    name: 'home',
    component: HomePageView,
    meta: { requiresAuth: true }
  },
  {
    path: '/walk-ins',
    name: 'walk-ins',
    component: WalkIns,
    meta: { requiresAuth: true }
  },
  {
    path: '/user-walk-ins',
    name: 'user-walk-ins',
    component: UserWalkInsConsole,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin-walk-ins',
    name: 'admin-walk-ins',
    component: AdminWalkInsConsole,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'login',
    component: LoginView,
  },

  {
    path: '/employees',
    name: 'employees',
    component: Employees,
    meta: { requiresAuth: true }
  },
  {
    path: '/policies',
    name: 'policies',
    component: Policies,
    meta: { requiresAuth: true }
  },
  {
    path: '/employeesform',
    name: 'employeesform',
    component: EmployeesFormView,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    component: function () {
      return import('../views/AboutView.vue')
    },
    meta: { requiresAuth: true }
  },
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/training-log',
    name: 'training-log',
    component: TrainingLogView,
    meta: { requiresAuth: true }
  },
  {
    path: '/new-training-log',
    name: 'new-training-log',
    component: NewTrainingLogView,
    meta: { requiresAuth: true }
  },
  {
    path: '/trainer-interface',
    name: 'trainer-interface',
    component: TrainerInterface,
    meta: { requiresAuth: true }
  },
  {
    path: '/new-trainer-interface',
    name: 'new-trainer-interface',
    component: NewTrainerInterface,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin-trainer-interface',
    name: 'admin-trainer-interface',
    component: AdminTrainerInterface,
    meta: { requiresAuth: true }
  },
  {
    path: '/new-admin-trainer-interface',
    name: 'new-admin-trainer-interface',
    component: NewAdminTrainerInterface,
    meta: { requiresAuth: true }
  },
  {
    path: '/locations',
    name: 'locations',
    component: Locations,
    meta: { requiresAuth: true }
  },
  {
    path: '/vehicles',
    name: 'vehicles',
    component: Vehicles,
    meta: { requiresAuth: true }
  },
  {
    path: '/instructors',
    name: 'instructors',
    component: Instructors,
    meta: { requiresAuth: true }
  },
  {
    path: '/zip-codes',
    name: 'zip-codes',
    component: ZipCodes,
    meta: { requiresAuth: true }
  },
  {
    path: '/chart-of-account',
    name: 'chart-of-account',
    component: ChartOfAccount,
    meta: { requiresAuth: true }
  },
  {
    path: '/zoho-chart-of-account',
    name: 'zoho-chart-of-account',
    component: ZohoChartOfAccountView,
    meta: { requiresAuth: true }
  },
  {
    path: '/expense-type',
    name: 'expense-type',
    component: ExpenseType,
    meta: { requiresAuth: true }
  },
  {
    path: '/banks',
    name: 'banks',
    component: Banks,
    meta: { requiresAuth: true }
  },
  {
    path: '/credit-card',
    name: 'credit-card',
    component: CreditCardManagementView,
    meta: { requiresAuth: true }
  },
  {
    path: '/credit-card-car',
    name: 'credit-card-car',
    component: CreditCardCarView,
    meta: { requiresAuth: true }
  },
  {
    path: '/paid-through-account',
    name: 'paid-through-account',
    component: PaidThroughAccountView,
    meta: { requiresAuth: true }
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: InsuranceView,
    meta: { requiresAuth: true }
  },
  {
    path: '/payroll',
    name: 'payroll',
    component: PayrollView,
    meta: { requiresAuth: true }
  },
  {
    path: '/email-validator',
    name: 'email-validator',
    component: EmailValidatorView,
    meta: { requiresAuth: true }
  },
  {
    path: '/type-of-coverage',
    name: 'type-of-coverage',
    component: TypeOfCoverageView,
    meta: { requiresAuth: true }
  },
  {
    path: '/type-of-comissions',
    name: 'type-of-comissions',
    component: TypeOfComissionsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/nova-analytics',
    name: 'nova-analytics',
    component: NovaAnalyticsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/expense-record',
    name: 'expense-record',
    component: ExpenseRecordView,
    meta: { requiresAuth: true }
  },
  {
    path: '/expense-record-admin-panel',
    name: 'expense-record-admin-panel',
    component: ExpenseRecordAdminPanelView,
    meta: { requiresAuth: true }
  },
  {
    path: '/lead-system',
    name: 'lead-system',
    component: LeadSystemView,
    meta: { requiresAuth: true }
  },
  {
    path: '/report-to',
    name: 'report-to',
    component: ReportToView,
    meta: { requiresAuth: true }
  },
  {
    path: '/computer-equipment',
    name: 'computer-equipment',
    component: ComputerEquipmentView,
    meta: { requiresAuth: true }
  },
  {
    path: '/computer-equipment-assignation',
    name: 'computer-equipment-assignation',
    component: ComputerEquipmentAssignationView,
    meta: { requiresAuth: true }
  },
  {
    path: '/zoho-vendors',
    name: 'zoho-vendors',
    component: ZohoVendorsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/zoho-currency',
    name: 'zoho-currency',
    component: ZohoCurrencyView,
    meta: { requiresAuth: true }
  },
  {
    path: '/zoho-banks',
    name: 'zoho-banks',
    component: ZohoBanksView,
    meta: { requiresAuth: true }
  },
  {
    path: '/porcentage-interface',
    name: 'porcentage-interface',
    component: PorcetagesInterfaceView,
    meta: { requiresAuth: true }
  },
  {
    path: '/location-excel',
    name: 'location-excel',
    component: LocationExcelView,
    meta: { requiresAuth: true }
  },
  {
    path: '/user-activity-report',
    name: 'user-activity-report',
    component: UserActivityReportView,
    meta: { requiresAuth: true }
  },  
  {
    path: '/admin-activity-report',
    name: 'admin-activity-report',
    component: AdminActivityReportView,
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const UserIsAuthenticated = isAuthenticated();
    if (!UserIsAuthenticated) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
